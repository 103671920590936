import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'index',
    },
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index'),
    meta: {
      title: '特权充值',
    },
  },
  {
    name: 'recharge',
    path: '/recharge',
    component: () => import('./view/recharge'),
    meta: {
      title: '特权充值',
    },
  },
  {
    name: 'rechargePhone',
    path: '/recharge/phone',
    component: () => import('./view/recharge/phone'),
    meta: {
      title: '话费充值',
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/order'),
    meta: {
      title: '订单列表',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
